var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "600px",
        top: "15vh",
        modal: false,
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                disabled: !_vm.isEdit,
                "label-position": "right",
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "版本号:", prop: "" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "version", $$v)
                      },
                      expression: "form.version",
                    },
                  }),
                ],
                1
              ),
              _vm.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "CODE:", prop: "" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "URL:", prop: "" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "url", $$v)
                          },
                          expression: "form.url",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "5",
                      placeholder: "更新说明",
                    },
                    model: {
                      value: _vm.form.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "note", $$v)
                      },
                      expression: "form.note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": "1", "false-label": "0" },
                      model: {
                        value: _vm.form.is_display,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_display", $$v)
                        },
                        expression: "form.is_display",
                      },
                    },
                    [_vm._v(" 显示更新提示 ")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": "1", "false-label": "0" },
                      model: {
                        value: _vm.form.is_must,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_must", $$v)
                        },
                        expression: "form.is_must",
                      },
                    },
                    [_vm._v(" 强制更新 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "指定USERID：" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "5",
                      placeholder:
                        "请输入用户ID，多个用户ID请用逗号隔开（例如：1，2，3）",
                    },
                    model: {
                      value: _vm.form.user_str,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "user_str", $$v)
                      },
                      expression: "form.user_str",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "指定手机品牌：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.brand_str,
                        callback: function ($$v) {
                          _vm.brand_str = $$v
                        },
                        expression: "brand_str",
                      },
                    },
                    _vm._l(_vm.brandList, function (city) {
                      return _c(
                        "el-checkbox",
                        { key: city.brand_id, attrs: { label: city.brand_id } },
                        [_vm._v(" " + _vm._s(city.brand_name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "指定安卓系统：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.sys_str,
                        callback: function ($$v) {
                          _vm.sys_str = $$v
                        },
                        expression: "sys_str",
                      },
                    },
                    _vm._l(_vm.sysList, function (city) {
                      return _c(
                        "el-checkbox",
                        { key: city.sys_id, attrs: { label: city.sys_id } },
                        [_vm._v(" " + _vm._s(city.sys_name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "5px", "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.isEdit
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v(" 保存 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }