<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="600px"
    top="15vh"
    :modal="false"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        :disabled="!isEdit"
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="版本号:" prop="">
          <el-input v-model="form.version"></el-input>
        </el-form-item>
        <el-form-item v-if="type == 1" label="CODE:" prop="">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item v-if="type == 1" label="URL:" prop="">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-input
            v-model="form.note"
            type="textarea"
            rows="5"
            placeholder="更新说明"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-checkbox v-model="form.is_display" true-label="1" false-label="0">
            显示更新提示
          </el-checkbox>
          <el-checkbox v-model="form.is_must" true-label="1" false-label="0">
            强制更新
          </el-checkbox>
        </el-form-item>
        <el-form-item label="指定USERID：">
          <el-input
            v-model="form.user_str"
            type="textarea"
            rows="5"
            placeholder="请输入用户ID，多个用户ID请用逗号隔开（例如：1，2，3）"
          ></el-input>
        </el-form-item>
        <el-form-item label="指定手机品牌：">
          <el-checkbox-group v-model="brand_str">
            <el-checkbox
              v-for="city in brandList"
              :key="city.brand_id"
              :label="city.brand_id"
            >
              {{ city.brand_name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="指定安卓系统：">
          <el-checkbox-group v-model="sys_str">
            <el-checkbox
              v-for="city in sysList"
              :key="city.sys_id"
              :label="city.sys_id"
            >
              {{ city.sys_name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 5px; text-align: right"
      >
        <el-button v-if="isEdit" type="primary" @click="handleSave">
          保存
        </el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { addVersion, editVersion } from '@/api/admin/appDev'
  export default {
    name: 'Edit',
    components: {},
    props: {
      brandList: {
        type: new Array(),
        default: () => {
          return new Array()
        },
      },
      sysList: {
        type: new Array(),
        default: () => {
          return new Array()
        },
      },
    },
    data() {
      return {
        ed: false, // 提交按钮是否是保存编辑
        type: null,
        isEdit: true,
        title: 'asd',
        showDialog: false,
        form: {
          version: '',
          note: '',
          is_display: '0', // 是否显示更新1显示0不显示
          is_must: '0', //是否强制更新1是0否
          code: '',
          url: '',
          brand_str: '',
          sys_str: '',
        },
        brand_str: [],
        sys_str: [],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.ed = false
          this.form = {
            version: '',
            note: '',
            is_display: '0', // 是否显示更新1显示0不显示
            is_must: '0', //是否强制更新1是0否
            brand_str: '',
            sys_str: '',
          }
          ;(this.brand_str = []), (this.sys_str = [])
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // ? 保存事件
      handleSave() {
        this.form.brand_str = this.brand_str.join(',')
        this.form.sys_str = this.sys_str.join(',')
        let end = {
          ...this.form,
          ...{
            from: this.type,
          },
        }
        if (this.ed) {
          // 是保存编辑
          editVersion(end).then(() => {
            this.$message.success('修改成功')
            this.close()
            this.$emit('refresh')
          })
        } else {
          addVersion(end).then((res) => {
            this.$message.success('添加成功')
            this.close()
            this.$emit('refresh')
          })
        }
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
