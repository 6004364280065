<template>
  <div class="orderTest-container">
    <div class="top">
      <h3>版本记录：</h3>
      <el-button v-if="type == 1" type="primary" @click="handleRelease">
        安卓发版
      </el-button>
      <el-button v-if="type == 2" type="primary" @click="handleRelease">
        苹果发版
      </el-button>
    </div>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column
        prop="version"
        label="版本号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="note"
        label="版本描述"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_at"
        label="发布时间"
        align="center"
      ></el-table-column>
      <el-table-column prop="" align="center" label="操作" fixed="right">
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>
          <el-button
            v-if="row.delete_at == 0"
            type="text"
            @click.native.prevent="handleEdit($index, row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="row.delete_at == 0"
            type="text"
            @click.native.prevent="handleUndo($index, row)"
          >
            撤回
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit
      ref="edit"
      :brand-list="brand_list"
      :sys-list="sys_list"
      @refresh="fetchData"
    ></edit>
  </div>
</template>
<script>
  import { versionList, undoVersion } from '@/api/admin/appDev'
  import Edit from './components/edit'
  export default {
    name: 'AndroidDev',
    components: { Edit },
    props: {
      type: {
        type: Number,
        default: () => 1,
      },
    },
    data() {
      return {
        loading: false,
        // type: 1, //安卓1 ios2
        list: [],
        total: 0,
        form: {
          pageSize: 10,
          pageNo: 1,
        },
        layout: 'total, sizes, prev, pager, next, jumper',
        brand_list: [],
        sys_list: [],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let end = {
          ...this.form,
          ...{ platform: this.type },
        }
        console.log('e', end)
        let { data, totalCount } = await versionList(
          end
          //   {
          //   ...this.form,
          //   ...{ platform: this.type },
          // }
        )
        // let { data, totalCount } = await versionList({
        //   ...this.form,
        //   ...{ platform: this.type },
        // })
        this.total = totalCount
        this.list = data
        // console.log(this.list)
        this.loading = false
        if (this.list) {
          this.brand_list = this.list[0].brand_list
          this.sys_list = this.list[0].sys_list
        }
      },
      handleRelease() {
        this.$refs.edit.type = this.type
        this.$refs.edit.title = this.type == 1 ? '安卓发版' : '苹果发版'
        this.$refs.edit.isEdit = true
        this.$refs.edit.showDialog = true
      },

      handleCheck(index, row) {
        this.$refs.edit.title = '查看'
        this.$refs.edit.type = this.type
        this.$refs.edit.isEdit = false
        this.$refs.edit.form = row
        this.$refs.edit.brand_str = row.brand_str
          .split(',')
          .map((list) => Number(list))
        this.$refs.edit.sys_str = row.sys_str
          .split(',')
          .map((list) => Number(list))
        this.$refs.edit.showDialog = true
      },
      handleEdit(index, row) {
        this.$refs.edit.title = '修改'
        this.$refs.edit.type = this.type
        this.$refs.edit.isEdit = true
        this.$refs.edit.ed = true
        this.$refs.edit.form = row
        this.$refs.edit.brand_str = row.brand_str
          .split(',')
          .map((list) => Number(list))
        this.$refs.edit.sys_str = row.sys_str
          .split(',')
          .map((list) => Number(list))
        this.$refs.edit.showDialog = true
      },
      handleUndo(index, row) {
        this.$confirm('确定撤回此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            undoVersion({ id: row.id }).then(() => {
              this.$message.success('撤回成功')
              this.fetchData()
            })
          })
          .catch(() => {
            console.log('no')
          })
      },

      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
