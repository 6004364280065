var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("h3", [_vm._v("版本记录：")]),
          _vm.type == 1
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleRelease },
                },
                [_vm._v(" 安卓发版 ")]
              )
            : _vm._e(),
          _vm.type == 2
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleRelease },
                },
                [_vm._v(" 苹果发版 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { prop: "version", label: "版本号", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "note", label: "版本描述", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "create_at", label: "发布时间", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "", align: "center", label: "操作", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleCheck($index, row)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    row.delete_at == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleEdit($index, row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _vm._e(),
                    row.delete_at == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleUndo($index, row)
                              },
                            },
                          },
                          [_vm._v(" 撤回 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", {
        ref: "edit",
        attrs: { "brand-list": _vm.brand_list, "sys-list": _vm.sys_list },
        on: { refresh: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }